// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// Rails.start()

require("@hotwired/turbo-rails");

Turbo.session.drive = false; // disable turbo

window.jQuery = require("jquery");
require("jquery-ujs");

require("jquery-ui-dist/jquery-ui");
window.$ = window.jQuery361 = jQuery; // fuck! где-то перебивается jQuery =(

import * as Popper from "@popperjs/core";

window.Popper = Popper;
window.bootstrap = require("bootstrap");
require("js-cookie");

require("scripts/libs/slick.min");
require("scripts/libs/jquery.inputmask.min");
require("scripts/libs/inputmask.binding");
require("scripts/libs/multipart-form");
require("scripts/libs/social-share-button");
require("scripts/libs/loader");
require("scripts/main");

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})

let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
});
